import React, { useState, useEffect } from 'react';
import { Bars } from 'react-loader-spinner';

import { RootStoreProvider, setupRootStore } from './store';
import App from './App';

// Loader
export const Loader = () => {
  return (
    <div className='pageLoader'>
      <Bars color='#3e71f3' height={80} width={80} />
    </div>
  );
};

//   APP
const RootApp = () => {
  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        setupRootStore().then(setRootStore);
      })();
    }, 5000);
  }, []);

  if (!rootStore) return <Loader></Loader>;

  return (
    <RootStoreProvider value={rootStore}>
      <App />
    </RootStoreProvider>
  );
};

export default RootApp;
