import React, { Suspense } from 'react';
import { Bars } from 'react-loader-spinner';
import { Route, useLocation, Routes } from 'react-router-dom';

// UTILITIES
import routes from './routes';

// SCREENS
const HomeScreen = React.lazy(() => import('../pages/HomeScreen'));
const AboutScreen = React.lazy(() => import('../pages/AboutScreen'));
const ServiceScreen = React.lazy(() => import('../pages/ServiceScreen'));
const WhereToFindUs = React.lazy(() => import('../pages/WhereToFindUs'));
const ProjectCalculator = React.lazy(() =>
  import('../pages/ProjectCalculator')
);
const MainProjectCalculator = React.lazy(() =>
  import('../pages/MainProjectCalculator')
);
const ProjectCalculatorResults = React.lazy(() =>
  import('../pages/ProjectCalculatorResults')
);
const ServiceDetailsScreen = React.lazy(() =>
  import('../pages/ServiceDetailsScreen')
);
const ProjectScreen = React.lazy(() => import('../pages/ProjectScreen'));
const ProjectDetailsScreen = React.lazy(() =>
  import('../pages/ProjectDetailsScreen')
);

const Ikana = () => {
  const location = useLocation();
  return (
    <Suspense
      fallback={
        <div className='pageLoader'>
          <Bars color='#3e71f3' height={80} width={80} />
        </div>
      }
    >
      <Routes location={location} key={location.pathname}>
        <Route path={routes.HOME} element={<HomeScreen />} exact />
        <Route path={routes.ABOUT} element={<AboutScreen />} exact />
        <Route path={routes.SERVICES} element={<ServiceScreen />} exact />
        <Route
          path={routes.SERVICE_DETAILS}
          element={<ServiceDetailsScreen />}
          exact
        />
        <Route path={routes.PROJECTS} element={<ProjectScreen />} exact />
        <Route
          path={routes.PROJECT_DETAILS}
          element={<ProjectDetailsScreen />}
          exact
        />
        {routes.PROJECT_DETAILS_SLUGS.map((item) => (
          <Route
            path={`/project/${item.slug}`}
            element={<ProjectDetailsScreen id={item.id} meta={item.meta}/>}
            exact
          />
        ))}
        {routes.SERVICE_DETAILS_SLUGS.map((item) => (
          <Route
            path={`/service/${item.slug}`}
            element={<ServiceDetailsScreen id={item.id} meta={item.meta}/>}
            exact
          />
        ))}
        <Route
          path={routes.WHERE_TO_FIND_US}
          element={<WhereToFindUs />}
          exact
        />
        <Route
          path={routes.PROJECT_ESTIMATION}
          element={<ProjectCalculator />}
          exact
        />
        <Route
          path={routes.START_PROJECT_ESTIMATION}
          element={<MainProjectCalculator />}
          exact
        />
        <Route
          path={routes.FINISH_PROJECT_ESTIMATION}
          element={<ProjectCalculatorResults />}
          exact
        />
      </Routes>
    </Suspense>
  );
};

export default Ikana;
