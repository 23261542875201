import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { Home } from "../models/home-screen/store";
import { Menu } from "../models/global-menu/store";
import { Footer } from "../models/footer/store";
import { About } from "../models/about-screen/store";


export const RootStoreModel = types.model("RootStore").props({
  menuStore:types.optional(Menu,{} as any),
  homePageStore:types.optional(Home,{} as any),
  aboutPageStore:types.optional(About,{} as any),
  footerStore:types.optional(Footer,{} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
