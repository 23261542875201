import { types } from "mobx-state-tree";

// ====== Menu Schema ======

export const MenuLinksSchema = {
  title: types.string,
  url: types.string,
};

export const MenuLinksObject = types
  .model("MenuLinksObject")
  .props(MenuLinksSchema);

export const SocialLinksSchema = {
  icon: types.string,
  url: types.string,
};

export const SocialLinksObject = types
  .model("SocialLinksObject")
  .props(SocialLinksSchema);

export const MenuSchema = {
  title: types.string,
  email: types.string,
  first_column: types.array(MenuLinksObject),
  second_column: types.array(MenuLinksObject),
  third_column: types.array(MenuLinksObject),
  social_links: types.array(SocialLinksObject),
};

export const MenuObject = types.model("MenuObject").props(MenuSchema);
