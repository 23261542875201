import { types } from "mobx-state-tree";

// ====== Footer Schema ======

export const FooterLinksSchema = {
  title: types.string,
  url: types.string,
};

export const FooterLinksObject = types
  .model("FooterLinksObject")
  .props(FooterLinksSchema);

export const SocialLinksSchema = {
  icon: types.string,
  url: types.string,
};

export const SocialLinksObject = types
  .model("SocialLinksObject")
  .props(SocialLinksSchema);

export const FooterSchema = {
  email: types.string,
  location: types.array(types.string),
  title: types.string,
  links: types.array(FooterLinksObject),
  social_links: types.array(SocialLinksObject),
};

export const FooterObject = types.model("FooterObject").props(FooterSchema);
