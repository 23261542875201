import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { observer } from "mobx-react-lite";
import Ikana from "./navigation";
import ScrollToTop from "./components/elements/ScrollToTop";

const App = observer(() => {
  return (
    <Router>
      <ScrollToTop />
      <Ikana />
    </Router>
  );
});

export default App;
