import { types } from "mobx-state-tree";
import { withEnvironment } from "../../";
import * as schemas from "./schemas";

export const Menu = types
  .model("Menu")
  .props({
    menu: types.maybeNull(schemas.MenuObject),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    _updateMenu: (data) => {
      self.menu = schemas.MenuObject.create(data);
    },
  }));
